<template>
<!-- customer -->
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
         <div class="col col-lg-auto">
              <b-button
                variant="success"
                @click="ExportExcel"
              > 
                <feather-icon icon="DownloadIcon" /> {{ $t('globalActions.excel') }}
              </b-button>
          </div>
          <div class="col-md-auto">
              <b-button
                variant="primary"
                :to="{ name: 'apps-master-data-contact-add'}"
              >
                <feather-icon icon="PlusIcon" /> {{ $t('globalActions.create') }}
              </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
 
    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: Brand and contact name -->
      <template #cell(brand)="data">
          <b-link
            :to="{ name: 'apps-master-data-contact-detail', params: { id: data.item.id }}"
            class="font-weight-bold"
          >
          <span class="font-weight-bolder d-block text-nowrap">{{ data.item.code }} - {{ data.item.brand }}</span>
          <small class="font-italic text-secondary">{{ data.item.brand_phone }}</small>
          </b-link>
      </template>

      <!-- Column: PIC -->
      <template #cell(picName)="data">
          <span class="font-weight-bolder d-block text-nowrap">{{ data.item.pic_name }}</span>
          <small class="font-italic text-secondary"> ( {{ data.item.pic_handphone }} )</small>
      </template>
      
      <!-- Column: address -->
      <template #cell(address)="data">
        {{ data.item.billing_address }}
      </template>

      <!-- Column: contact type -->
      <template #cell(type)="{ item }">
        <span class="font-weight-bold d-block text-nowrap">
          <div class="d-flex">
          <b-badge v-for="(types, index) in item.type" :key="index" pill variant="light-primary">{{ types.name }}</b-badge>
        </div>
        </span>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { unformatNumber } from '@/utils/formatter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge
  },
  setup () {
    const { $t } = useLocalization()
    // Table Handlers
    const tableColumns = [
      { key: 'brand', label: 'Contact' },
      { key: 'picName', label: 'PIC' },
      { key: 'address', label: 'Address' },
      { key: 'office_id', label: 'Office' },
      { key: 'type', label: 'Type' },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'brand',
      'picName',
      'address',
      'type'
    ]

    const contactType = '{CTCS}'
    
    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: `master/contact?type=${contactType}`
      })
    }
  },
    methods: {
    ExportExcel () {
       import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists
        for (let index = 0; index < list.length; index++) {
          list[index].audit = list[index].updated_process + ' By ' + list[index].getUser.user_name + ' Date ' + list[index].updated_at
          list[index].picName = list[index].pic_name
          list[index].address = list[index].billing_address
          let type = ''
          for (let i = 0; i < list[index].type.length; i++) {
            type = type + ' ' + list[index].type[i].name
          }
          list[index].type = type


        }

        const date = new Date();
        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
      
        let currentDate = `${day}-${month}-${year}`;

        const headerTitle = this.tableColumns.map(column => column.label)

        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            ['Master Data', '', '', '', '', '', ''],
            ['Jurnal Template', '', '', '', '', '', ''],
            [currentDate, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Master data Contact`,
          autoWidth: true,
          bookType: 'xlsx'
        })

      })

    },
        formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
